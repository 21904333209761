<template>
  <Layout>
    <div class="row" v-if="selectedInspection">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <div class="row">
              <div class="col-12">Inspection Info</div>
            </div>
            <div class="row">
              <div class="col-4">Amount Paid</div>
              <div class="col-8" v-if="selectedInspection">
                {{ selectedInspection.amount }}
              </div>
            </div>
            <div class="row">
              <div class="col-4">Starting Milage</div>
              <div class="col-8" v-if="selectedInspection">
                {{ selectedInspection.starting_milage }}
              </div>
            </div>
             <div class="row">
              <div class="col-4">End Milage</div>
              <div class="col-8" v-if="selectedInspection">
                {{ selectedInspection.end_milage }}
              </div>
            </div>
             <div class="row">
              <div class="col-4">Milage Driven</div>
              <div class="col-8" v-if="selectedInspection">
                {{ selectedInspection.end_milage - selectedInspection.starting_milage }} km  ({{selectedInspection.flat_rate}} earned per km)
              </div>
            </div>
            <div class="row">
              <div class="col-4">Driver ID</div>
              <div class="col-8" v-if="selectedInspection">{{ selectedInspection.user._id }}</div>
            </div>
            <div class="row">
              <div class="col-4">Driver Email</div>
              <div class="col-8" v-if="selectedInspection">{{ selectedInspection.user.email }}</div>
            </div>
            
            <div class="row">
              <div class="col-4">Date of Inspection</div>
              <div class="col-8" v-if="selectedInspection">{{ moment(selectedInspection.date).format("lll") }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="card" v-if="selectedInspection">
      <div class="card-body">
        <div class="row">
          <div class="col-4">Driver Milage Evidence</div>
          <div class="col-8">
            <a :href="selectedInspection.milage_evidence" target="_blank">
              <img width="50%" :src="selectedInspection.milage_evidence" />
            </a>
          </div>
        </div>
        <div class="row">
          <button type="button" class="btn btn-primary" @click="updateMilageModal">
            Update Milage
          </button>
        </div>
      </div>
    </div>
    <InspectionModal 
      :selectedInspection="selectedInspection"
      v-on:reloadData="loadInspection"
      v-on:closeModal="hideActionModal"
      />
  </Layout>
</template>
<style>
.vehicleinfo {
  cursor: pointer;
}
</style>
<script>
import Layout from "../../layouts/main";
import { inspections } from "@/config/api/inspections";
import InspectionModal from "@/components/inspections/inspection-modal"
export default {
    props: ["id"],

  components: {
    Layout,
    InspectionModal
  },
  data() {
    return {
      isBusy: false,
      selectedInspection: undefined,
    };
  },
  mounted() {
    this.loadInspection();
  },
  methods: {
    updateMilageModal(){
      this.$bvModal.show("inspection-modal");
    },
    hideActionModal() {
      this.$bvModal.hide("inspection-modal");
    },
    selectedInspectionSelected(data) {
      this.$router.push({ name: "InspectionDetails", params: { id: data._id } });
    },
    loadInspection() {
        console.log(this.$route.params.id)
      this.isBusy = true;
      let api = inspections.getById;
      api.id = this.$route.params.id;
      this.generateAPI(api)
        .then((res) => {
          console.log(res);
          this.selectedInspection = res.data.inspection;

          this.isBusy = false;
        })
        .catch((err) => {
          console.error(err);
        });
      console.log("Load Inspection profile!");
    },
  },
};
</script>

<style>
.spaceDetails {
  justify-content: space-between;
}
</style>