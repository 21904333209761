<template>
  <b-modal
    id="inspection-modal"
    @hidden="$emit('resetModal')"
    title='Update Milage'
    hide-footer
  >
    <FormulateForm
      name="BankAction"
      @submit="submitHandler"
    >
    <FormulateInput
            v-model="end_milage"
            type="number"
            min="0"
            label="End Milage"
          />
      <FormulateInput type="submit" class="float-right">
        <span v-if="performAction">
          <b-spinner class="vueformulate-loader"></b-spinner>
          <span v-if="selectedInspection"> Updating... </span>
          <span v-else> Adding... </span>
        </span>
        <span v-else>
          <span v-if="selectedInspection"> Update </span>
          <span v-else> Add </span>
          Bank</span
        >
      </FormulateInput>
    </FormulateForm>
  </b-modal>
</template>

<script>
import { inspections } from "@/config/api/inspections";
export default {
  props: ["selectedInspection"],
  data() {
    return {
      end_milage: 0,
      performAction: false,
    };
  },
  methods: {
    async submitHandler() {
        this.updateInspection();
    },
    updateInspection() {
      this.performAction = true;
      const api = inspections.update;
      api.data = {inspectionId: this.selectedInspection._id, end_milage: this.end_milage};
      this.generateAPI(api)
        .then((res) => {
          console.log(res.data);
          this.$bvToast.toast("Inspection updated successfully!", {
            title: "Update",
            variant: "success",
            solid: true,
          });
          this.$emit("reloadData");
        })
        .catch((err) => {
          this.$bvToast.toast("Inspection is not updated!", {
            title: "Update",
            variant: "danger",
            solid: true,
          });
          console.error(err.response.data);
        })
        .finally(() => {
          this.performAction = false;
        });
    },
  },
  watch: {
    selectedInspection(val) {
      if (val) {
        this.end_milage = val.end_milage;
      }
    },
  }
};
</script>

<style>
.payment-duration .formulate-input-group {
  display: flex;
}

.payment-duration .formulate-input-group-item {
  margin: 8px;
}
.payment-duration .formulate-input-label {
  margin-top: -6px;
}
</style>